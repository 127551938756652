<template>
  <div class="competition">
    <div class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logoBlack.png" alt="" />
    </div>
    <div class="banner">
      <span @click="openJoin" class="btn1" alt="">
        立即报名
      </span>
    </div>
    <div class="content">
      <div class="part-1">
        <div class="part-title">财富共创，盈利共享</div>
        <div class="part-1-tips">
          挑战成功，赢取<span class="orange">现金分成</span>大礼！
        </div>
        <div class="part-1-step">
          <img src="/activity/competition/step.png" alt="" />
        </div>
        <div class="part-1-box">
          <img src="/activity/competition/group_1.png" width="100%">
          <div class="part-1-box-right">
            <div>
              <img src="/activity/competition/check_icon.png" alt="">
              <span>解锁实盘账户</span>
            </div>
            <div>
              <img src="/activity/competition/check_icon.png" alt="">
              <span>盈利可提现，分润比例高达<span class="orange">60%</span></span>
            </div>
            <div>
              <img src="/activity/competition/check_icon.png" alt="">
              <span>【交易王者】勋章，记录您的荣耀时刻</span>
            </div>
          </div>
        </div>
        <div class="part-1-btn">
          <div class="part-1-btn-join" @click="openJoin">参与挑战</div>
          <div class="part-1-btn-server" @click="openLive">咨询客服</div>
        </div>
        <div class="part-1-introduce">
          赵先生报名参赛，初始模拟资金<span class="orange">80000美元</span>。首周凭借非凡的交易才能，盈利8000美元晋级新手赛；次周再赚4000美元，通过精英认证，<span class="orange">一举夺得“交易王者”称号，晋级实盘交易</span>。<br>
          转战实盘，赵先生选择操作伦敦金，连续三月分别盈利9020美元、12053.6美元、15133.9美元。总资金增长至116207.5美元（80000+9020+12053.6+15133.9）。<br>
          此时赵先生按60%提取分润，同时获得返还的报名费，<span class="orange">总计高达23324.5美元</span>，收获了知识与策略的丰厚回报。
        </div>
      </div>
      <div class="part-2">
        <div class="part-title">三段赛事，锁定交易目标</div>
        <div class="role-tab">
          <span class="role-tab-title">账户资金：</span>
          <span 
            :class="`role-tab-item orange ${selectRole === item.value && 'role-tab-item-active'}`" 
            v-for="item in roleTab" 
            :key="item.value"
            @click="selectRole = item.value"
          >
            {{ item.label }}
          </span>
        </div>
        <table class="role-table">
          <img class="logo-bg" src="/activity/competition/logo_bg.png" alt="">
          <thead>
            <tr>
              <td> </td>
              <td>选拔赛</td>
              <td>认证赛</td>
              <td>分润</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in roleList" :key="'roel-' + item.name">
              <td>{{ item.name }}</td>
              <td>{{ item.trials || '--' }}</td>
              <td>{{ item.certification || '--' }}</td>
              <td>{{ item.profit || '--' }}</td>
            </tr>
          </tbody>
        </table>
        <div class="part-2-tips">首次分润即可全额返还报名费，再享<span class="orange">60%</span>盈利分润！</div>
        <div @click="openJoin" class="part-2-btn">参与挑战</div>
      </div>
      <div class="part-3">
        <div class="part-title">交易王者璀璨殿堂</div>
        <swiper class="swiper-box" ref="videoSwiper" :options="swiperOption"> 
          <div class="swiper-slide">
            <img src="/activity/competition/rank_1.png" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="/activity/competition/rank_2.png" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="/activity/competition/rank_3.png" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="/activity/competition/rank_4.png" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="/activity/competition/rank_5.png" alt="" />
          </div>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="question-box">
          <div class="question-header">
            常见问题：
          </div>
          <div class="question-item">
            <div class="question-item-title">
              <img src="/activity/competition/right_icon.png" alt="">
              <span>实盘交易怎么分润？</span>
            </div>
            <div class="question-item-content">
              开始实盘交易后，每 14 天可分润一次。分润次数不可累计,申请分润时账户不可有持仓订单。
            </div>
          </div>
          <div class="question-item">
            <div class="question-item-title">
              <img src="/activity/competition/right_icon.png" alt="">
              <span>参赛对象有要求吗？</span>
            </div>
            <div class="question-item-content">
              希望您年满18岁，并且有一定的交易经验。
            </div>
          </div>
          <div class="question-item">
            <div class="question-item-title">
              <img src="/activity/competition/right_icon.png" alt="">
              <span>赛程安排是怎样的？</span>
            </div>
            <div class="question-item-content">
              分为新手选拔赛、精英认证赛、交易王者三个阶段。当前阶段挑战成功后，自动进入下一阶段。
            </div>
          </div>
          <div class="question-item">
            <div class="question-item-title">
              <img src="/activity/competition/right_icon.png" alt="">
              <span>怎么才算挑战成功？</span>
            </div>
            <div class="question-item-content">
              1.在规定期限内实现利润目标，选拔赛目标是初始额度的10%，认证赛是 5%。 <br>
              2.有效交易天数不低于最小交易日。 <br>
              3.避免违反挑战规则。一旦违反则账户回收，挑战失败。<br>
            </div>
          </div>
          <div class="question-item">
            <div class="question-item-title">
              <img src="/activity/competition/right_icon.png" alt="">
              <span>什么情况下会账户回收？（以账户资金10000美元为例）</span>
            </div>
            <div class="question-item-content">
              1.每日最大亏损：<br>
              当前日亏损=当日平仓盈亏+当日持仓浮动盈亏。在一个交易日的任何时间，浮动亏损超过 10,000 * 5% = 500美元且账户余额低于 10,000 美元 - 10,000 * 5% = 9,500 美元，即视为违反规则。每日最大亏损包含未平仓交易，如果持仓过夜 前一日的浮动亏损会算到当日浮动亏损中。<br>
              <div class="orange">*每日最大亏损将于6：00点重置，请及时平仓避免超出。</div>
              2.总亏损：<br>
              在整个交易期间，交易账户的净值在任何时候都不得低于初始账户余额的 90%，即整体净值低于$10,000 - $10,000 * 10% = $9,000，即视为违反规则，账户回收。<br>
              3.闲置日：<br>
              当在任何阶段期间，用户连续 20 天未进行交易即视为违反规则，账户回收。
              4.限制交易：<br>
              不允许新闻交易，新闻公告发布前2分钟至2分钟的时间内进行目标品种的开仓平仓行为，即视为违反规则，账户回收。<br>
            </div>
          </div>
          <div class="question-item">
            <div class="question-item-title">
              <img src="/activity/competition/right_icon.png" alt="">
              <span>挑战失败可以再次报名吗？</span>
            </div>
            <div class="question-item-content">
              可以，如再次报名需要重新缴纳报名费。
            </div>
          </div>
        </div>
      </div>
    </div>
    <contactusVue></contactusVue>
    <img v-if="scroll > 1000" class="top-icon" src="/activity/competition/top_icon.png" width="40" alt=""  @click="onScrollTo">
  </div>
</template>

<script>
import ContactusVue from '../../Contactus.vue';
export default {
  name: 'traderCompetitionPc',
  components: {
    ContactusVue,
  },
  data() {
    return {
      screenWidth: null,
      navBarFixed: false,
      scroll: '',
      roleTab: [
        { label: '$10000', value: 'role1', },
        { label: '$20000', value: 'role2', },
        { label: '$30000', value: 'role3', },
        { label: '$40000', value: 'role4', },
        { label: '$50000', value: 'role5', },
        { label: '$60000', value: 'role6', },
        { label: '$70000', value: 'role7', },
        { label: '$80000', value: 'role8', },
        { label: '$90000', value: 'role9', },
        { label: '$100000', value: 'role10', },
      ],
      roleInfo: {
        role1: [
          { name: '报名费（2%）', trials: '$200', certification: '无', profit: '退还' },
          { name: '交易期限', trials: '无限制', certification: '无限制', profit: '无限制' },
          { name: '最小交易日', trials: '4', certification: '6', profit: '无限制' },
          { name: '利润目标', trials: '$1000', certification: '$500', profit: '无' },
          { name: '每日最大亏损（5%）', trials: '$500', certification: '$500', profit: '$500' },
          { name: '总亏损（10%）', trials: '$1000', certification: '$1000', profit: '$1000' },
          { name: '分润周期', trials: '', certification: '', profit: '14天' },
          { name: '分润比例', trials: '', certification: '', profit: '50%' },
        ],
        role2: [
          { name: '报名费（2%）', trials: '$400', certification: '无', profit: '退还' },
          { name: '交易期限', trials: '无限制', certification: '无限制', profit: '无限制' },
          { name: '最小交易日', trials: '4', certification: '6', profit: '无限制' },
          { name: '利润目标', trials: '$2000', certification: '$1000', profit: '无' },
          { name: '每日最大亏损（5%）', trials: '$1000', certification: '$1000', profit: '$1000' },
          { name: '总亏损（10%）', trials: '$2000', certification: '$2000', profit: '$2000' },
          { name: '分润周期', trials: '', certification: '', profit: '14天' },
          { name: '分润比例', trials: '', certification: '', profit: '50%' },
        ],
        role3: [
          { name: '报名费（2%）', trials: '$600', certification: '无', profit: '退还' },
          { name: '交易期限', trials: '无限制', certification: '无限制', profit: '无限制' },
          { name: '最小交易日', trials: '4', certification: '6', profit: '无限制' },
          { name: '利润目标', trials: '$3000', certification: '$1500', profit: '无' },
          { name: '每日最大亏损（5%）', trials: '$1500', certification: '$1500', profit: '$1500' },
          { name: '总亏损（10%）', trials: '$3000', certification: '$3000', profit: '$3000' },
          { name: '分润周期', trials: '', certification: '', profit: '14天' },
          { name: '分润比例', trials: '', certification: '', profit: '50%' },
        ],
        role4: [
          { name: '报名费（2%）', trials: '$800', certification: '无', profit: '退还' },
          { name: '交易期限', trials: '无限制', certification: '无限制', profit: '无限制' },
          { name: '最小交易日', trials: '4', certification: '6', profit: '无限制' },
          { name: '利润目标', trials: '$4000', certification: '$2000', profit: '无' },
          { name: '每日最大亏损（5%）', trials: '$2000', certification: '$2000', profit: '$2000' },
          { name: '总亏损（10%）', trials: '$4000', certification: '$4000', profit: '$4000' },
          { name: '分润周期', trials: '', certification: '', profit: '14天' },
          { name: '分润比例', trials: '', certification: '', profit: '50%' },
        ],
        role5: [
          { name: '报名费（2%）', trials: '$1000', certification: '无', profit: '退还' },
          { name: '交易期限', trials: '无限制', certification: '无限制', profit: '无限制' },
          { name: '最小交易日', trials: '4', certification: '6', profit: '无限制' },
          { name: '利润目标', trials: '$5000', certification: '$2500', profit: '无' },
          { name: '每日最大亏损（5%）', trials: '$2500', certification: '$2500', profit: '$2500' },
          { name: '总亏损（10%）', trials: '$5000', certification: '$5000', profit: '$5000' },
          { name: '分润周期', trials: '', certification: '', profit: '14天' },
          { name: '分润比例', trials: '', certification: '', profit: '50%' },
        ],
        role6: [
          { name: '报名费（2%）', trials: '$1200', certification: '无', profit: '退还' },
          { name: '交易期限', trials: '无限制', certification: '无限制', profit: '无限制' },
          { name: '最小交易日', trials: '4', certification: '6', profit: '无限制' },
          { name: '利润目标', trials: '$6000', certification: '$3000', profit: '无' },
          { name: '每日最大亏损（5%）', trials: '$3000', certification: '$3000', profit: '$3000' },
          { name: '总亏损（10%）', trials: '$6000', certification: '$6000', profit: '$6000' },
          { name: '分润周期', trials: '', certification: '', profit: '14天' },
          { name: '分润比例', trials: '', certification: '', profit: '50%' },
        ],
        role7: [
          { name: '报名费（2%）', trials: '$1400', certification: '无', profit: '退还' },
          { name: '交易期限', trials: '无限制', certification: '无限制', profit: '无限制' },
          { name: '最小交易日', trials: '4', certification: '6', profit: '无限制' },
          { name: '利润目标', trials: '$7000', certification: '$3500', profit: '无' },
          { name: '每日最大亏损（5%）', trials: '$3500', certification: '$3500', profit: '$3500' },
          { name: '总亏损（10%）', trials: '$7000', certification: '$7000', profit: '$7000' },
          { name: '分润周期', trials: '', certification: '', profit: '14天' },
          { name: '分润比例', trials: '', certification: '', profit: '50%' },
        ],
        role8: [
          { name: '报名费（2%）', trials: '$1600', certification: '无', profit: '退还' },
          { name: '交易期限', trials: '无限制', certification: '无限制', profit: '无限制' },
          { name: '最小交易日', trials: '4', certification: '6', profit: '无限制' },
          { name: '利润目标', trials: '$8000', certification: '$4000', profit: '无' },
          { name: '每日最大亏损（5%）', trials: '$4000', certification: '$4000', profit: '$4000' },
          { name: '总亏损（10%）', trials: '$8000', certification: '$8000', profit: '$8000' },
          { name: '分润周期', trials: '', certification: '', profit: '14天' },
          { name: '分润比例', trials: '', certification: '', profit: '60%' },
        ],
        role9: [
          { name: '报名费（2%）', trials: '$1800', certification: '无', profit: '退还' },
          { name: '交易期限', trials: '无限制', certification: '无限制', profit: '无限制' },
          { name: '最小交易日', trials: '4', certification: '6', profit: '无限制' },
          { name: '利润目标', trials: '$9000', certification: '$4500', profit: '无' },
          { name: '每日最大亏损（5%）', trials: '$4500', certification: '$4500', profit: '$4500' },
          { name: '总亏损（10%）', trials: '$9000', certification: '$9000', profit: '$9000' },
          { name: '分润周期', trials: '', certification: '', profit: '14天' },
          { name: '分润比例', trials: '', certification: '', profit: '60%' },
        ],
        role10: [
          { name: '报名费（2%）', trials: '$2000', certification: '无', profit: '退还' },
          { name: '交易期限', trials: '无限制', certification: '无限制', profit: '无限制' },
          { name: '最小交易日', trials: '4', certification: '6', profit: '无限制' },
          { name: '利润目标', trials: '$10000', certification: '$5000', profit: '无' },
          { name: '每日最大亏损（5%）', trials: '$5000', certification: '$5000', profit: '$5000' },
          { name: '总亏损（10%）', trials: '$10000', certification: '$10000', profit: '$10000' },
          { name: '分润周期', trials: '', certification: '', profit: '14天' },
          { name: '分润比例', trials: '', certification: '', profit: '60%' },
        ],
      },
      selectRole: 'role1',
      swiperOption: {
        slidesPerView: 2,
        centeredSlides: true,
        grabCursor:true,
        loop: true,
        preventClicks : true,
        //   //自动播放
        autoplay: {
          delay: 4000, //延迟播放下一张图片
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  computed: {
    roleList() {
      return this.roleInfo[this.selectRole]
    }
  },
  created() {
    this.$emit('controlShow', true);
    this.$emit("controlShow", false);
    this.$emit('controlBottom',false);
    // 判断是从A前还是A后跳转过来
    if (!sessionStorage.getItem('craig')) {
      if (!this.$route.query.craig) {
        sessionStorage.setItem('craig', window.location.origin);
      } else {
        sessionStorage.setItem('craig', this.$route.query.craig);
      }
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    // 返回上级页面
    goback() {
      window.location.href = sessionStorage.getItem('craig');
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    openJoin() {
      // window.open('https://www.rlcproltd.net/uc/login');
    },
    openLive() {
      window.open(
        this.$parent.live800Url
      );
    },
    onScrollTo() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动
      });
    },
  },
};
</script>

<style lang="less" scoped>

.competition {
  background: #f8f8f8;
  font-size: 16px;
  .orange {
    color: #FF5151;
  }
  .tabbar {
    background-color: #ffffff;
    padding: 10px 20px;

    img {
      width: 95px;
    }
  }

  .navBarFixed {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .banner {
    width: 100%;
    background: url('/activity/competition/banner.png') no-repeat center;
    background-size: cover;
    height: 132vw;
    position: relative;

    .btn1 {
      position: absolute;
      width: 64%;
      line-height: 40px;
      left: 50%;
      top: 58%;
      border-radius: 6px;
      text-align: center;
      cursor: pointer;
      font-size: 16px;
      color: #000;
      font-weight: 700;
      transform: translate(-50%, -50%);
      background: linear-gradient(to right, #FFD768, #FF5151);
    }
  }
  .content {
    margin: auto;
    color: #000;
    font-family: Source Han Sans SC;
    .part-title {
      font-size: 0.55rem;
      font-weight: 700;
      text-align: center;
      line-height: 0.78rem;
    }
    .part-1 {
      margin-top: 0.34rem;
      padding: 0 0.48rem;
      &-tips {
        text-align: center;
        margin-top: 0.17rem;
        font-size: 0.3rem;
      }
      &-step {
        img {
          width: 45vw;
          margin-left: 13vw;
        }
      }
      &-box {
        &-right {
          background: url('/activity/competition/boxBg_1.png') no-repeat center;
          background-size: 100% 100%;
          font-size: 0.3rem;
          font-weight: 500;
          padding: 65px 0 10px 0.51rem;
          margin-top: -35px;
          div {
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            &:last-child {
              img {
                margin-right: 10px;
              }
            }
          }
          img {
            width: 0.38rem;
            height: 0.38rem;
            margin-right: 14px;
          }
        }
      }
      &-btn {
        display: flex;
        justify-content: space-between;
        margin: 25px 0;
        div {
          width: 45%;
          line-height: 0.85rem;
          font-size: 0.33rem;
          font-weight: 700;
          color: #fff;
          text-align: center;
          cursor: pointer;
        }
        &-join {
          border-radius: 4px;
          background: linear-gradient(to right, #FFBC78, #FF5159);
        }
        &-server {
          background: url('/activity/competition/btn_bg.png') no-repeat center;
          background-size: 100% 100%;
          color: #FF5151 !important;
        }
      }
      &-introduce {
        background: url('/activity/competition/boxBg_2.png') no-repeat center;
        background-size: 100% 100%;
        font-size: 0.26rem;
        line-height: 0.48rem;
        padding: 28px 24px;
        box-sizing: border-box;
      }
    }
    .part-2 {
      margin-top: 30px;
      .role-tab {
        font-size: 0.272rem;
        font-weight: 700;
        margin-top: 20px;
        padding: 0 0.34rem;
        >span {
          display: inline-block;
          width: 22%;
          margin-right: 3.3%;
          text-align: center;
          &:nth-child(4n) {
            margin-right: 0;
          }
        }
        &-title {
          flex-shrink: 0;
          width: 150px;
          margin-top: 10px;
        }
        &-item {
          box-sizing: border-box;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          margin-bottom: 14px;
          background: #fff;
          cursor: pointer;
          line-height: 0.61rem;
        }
        &-item-active {
          color: #fff;
          border: none;
          background: linear-gradient(to right, #FFBC78, #FF5159);
        }
      }
      .role-table {
        margin: 0 0.48rem;
        width: calc(100% - 0.96rem);
        background: url('/activity/competition/role_bg.png') no-repeat center;
        background-size: 100% 100%;
        border: 1px solid #E8E8E8;
        border-spacing: 0;
        position: relative;
        thead {
          border-bottom: 1px solid #E8E8E8;
          td {
            background: #fff;
            border-bottom: 1px solid #E8E8E8;
            font-size: 0.24rem;
          }
        }
        tr {
          td {
            box-sizing: border-box;
            width: 25%;
            text-align: center;
            line-height: 0.75rem;
            height: 0.75rem;
            border-right: 1px solid #E8E8E8;
            font-size: 0.22rem;
            font-weight: 400;
            &:last-child {
              border-right: none;
            }
          }
        }
        .logo-bg {
          width: 53vw;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0.8;

        }
      }
      &-tips {
        text-align: center;
        margin: 15px 0 20px;
        font-size: 0.28rem;
        line-height: 37px;
        font-weight: 600;
      }
      &-btn {
        width: 75%;
        line-height: 0.85rem;
        border-radius: 6px;
        background: linear-gradient(to right, #FFC387, #FF5159);
        text-align: center;
        margin: auto;
        font-size: 0.34rem;
        font-weight: 700;
        color: #fff;
        cursor: pointer;
      }
    }
    .part-3 {
      margin-top: 30px;
      .swiper-box {
        padding:  28px 0 50px;
        .swiper-slide {
          width: 43% !important;
          margin: 0 3.5%;
          opacity: 0.2;
          img {
            width: 100%;
          }
        }
        .swiper-slide-active {
          opacity: 1;
        }
        .swiper-pagination {
          bottom: 30px;
          .swiper-pagination-bullet-active {
            background: #FF5159 !important;
          }
        }
        
      }
      .question-box {
        margin: 0 0.48rem;
        width: calc(100% - 0.96rem);
        background: url('/activity/competition/boxBg_3.png') no-repeat center;
        background-size: 100% 100%;
        padding: 20px;
        margin-bottom: 60px;
        box-sizing: border-box;
        .question-header {
          font-size: 0.34rem;
          font-weight: 700;
          line-height: 0.45rem;
          margin-bottom: 0.325rem;
        }
        .question-item {
          margin-bottom: 16px;
          &-title {
            display: flex;
            font-size: 0.28rem;
            font-weight: 700;
            margin-bottom: 10px;
            img {
              margin: -4px 2px 0 -7px;
              width: 0.55rem;
              height: 0.55rem;
            }
          }
          &-content {
            font-size: 0.26rem;
            line-height: 0.375rem;
          }
        }
      }
    }
  }
  .top-icon {
    position: fixed;
    right: 5px;
    bottom: 80px;
    cursor: pointer;
  }
}
.swiper-box ::v-deep {
  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
  }
  .swiper-pagination-bullet-active {
    background: #FF5159 !important;
  }
}
</style>
